import type { InitOptions } from 'i18next';

export const fallbackLng = 'en';
export const languages = [fallbackLng, 'nl', 'de', 'fr'] as const;
export type Locales = (typeof languages)[number];
export const defaultNS = 'common';
export const cookieName = 'i18next';

export const resources = {
    nl: {
        common: require('../../../public/locales/nl/common.json'),
        errors: require('../../../public/locales/nl/errors.json'),
        launchpad: require('../../../public/locales/nl/launchpad.json'),
        menu: require('../../../public/locales/nl/menu.json'),
        external: require('../../../public/locales/nl/external.json'),
        staff: require('../../../public/locales/nl/staff.json'),
    },
    en: {
        common: require('../../../public/locales/en/common.json'),
        events: require('../../../public/locales/en/events.json'),
        launchpad: require('../../../public/locales/en/launchpad.json'),
        menu: require('../../../public/locales/en/menu.json'),
        errors: require('../../../public/locales/en/errors.json'),
        external: require('../../../public/locales/en/external.json'),
        staff: require('../../../public/locales/en/staff.json'),
    },
    de: {
        common: require('../../../public/locales/de/common.json'),
        errors: require('../../../public/locales/de/errors.json'),
        launchpad: require('../../../public/locales/de/launchpad.json'),
        menu: require('../../../public/locales/de/menu.json'),
        external: require('../../../public/locales/de/external.json'),
        staff: require('../../../public/locales/de/staff.json'),
    },
    fr: {
        common: require('../../../public/locales/fr/common.json'),
        errors: require('../../../public/locales/fr/errors.json'),
    },
};

export function getOptions(lng = fallbackLng, ns = defaultNS): InitOptions {
    return {
        // debug: true,
        supportedLngs: languages,
        fallbackLng,
        lng,
        fallbackNS: defaultNS,
        defaultNS,
        resources,
        ns,
        interpolation: {
            escapeValue: false, // React already escapes values
        },
        react: {
            useSuspense: false,
        },
    };
}
