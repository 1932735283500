import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { cookieName, getOptions, languages } from './settings';

const runsOnServerSide = typeof window === 'undefined';
// https://carlogino.com/blog/nextjs-app-dir-i18n-cookie
// Initialize i18next for the client side

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        ...getOptions(),
        lng: undefined, // detect the language on the client
        detection: {
            // We only care about the cookie
            order: ['cookie'],
            // If `lookupCookie` is not set, it will use `i18next` as the cookie name
            lookupCookie: cookieName,
            // This will automatically update the cookie
            caches: ['cookie'],
        },
        preload: runsOnServerSide ? languages : [],
    })
    // eslint-disable-next-line no-console -- We want to log the error
    .catch(() => console.error('Failed to initialize i18next'));

export default i18n;
